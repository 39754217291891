@use 'styles/mixins' as *;

.addressContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  // Half of InputGroup's margin-bottom (15px)
  > * {
    margin-bottom: 7.5px;
  }

  // Remove margin from the last element
  > *:last-child {
    margin-bottom: 0;
  }
}

.error {
  color: $error-color;
  font-size: 1.1rem;
  text-transform: capitalize;

  &::before {
    content: '* ';
  }
}

.manualEntryLink {
  font-size: $font-size-lg;
}

.suggestionsList {
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  li {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
