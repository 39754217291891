/////////////////////////////////// TYPOGRAPHY /////////////////////////////////////

/***** FONT FAMILY *****/

$font-family-heading: 'URW Gothic L';
$font-family-body: 'Inter';

/***** FONT WEIGHT *****/

$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;

/***** FONT SIZE *****/

$font-size-xxs: 0.687rem; /* 11px */
$font-size-xs: 0.75rem; /* 12px */
$font-size-sm: 0.875rem; /* 14px */
$font-size-md: 1rem; /* 16px */
$font-size-lg: 1.125rem; /* 18px */
$font-size-xl: 1.25rem; /* 20px */
$font-size-xxl: 1.5rem; /* 24px */
$font-size-xxxl: 2rem; /* 32px */
$font-size-xxxxl: 2.25rem; /* 36px */
$font-size-xxxxxl: 3rem; /* 48px */

/***** FONT LINE HEIGHT *****/

$font-line-height-xxs: 1rem; /* 16px */
$font-line-height-xs: 1.125rem; /* 18px */
$font-line-height-sm: 1.3125rem; /* 21px */
$font-line-height-md: 1.5rem; /* 24px */
$font-line-height-lg: 1.6875rem; /* 27px */
$font-line-height-xl: 1.875rem; /* 30px */
$font-line-height-xxl: 2.25rem; /* 36px */
$font-line-height-xxxl: 2.625rem; /* 42px */
$font-line-height-xxxxl: 2.875rem; /* 46px */
$font-line-height-xxxxxl: 3.875rem; /* 62px */
