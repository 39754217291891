@use 'styles/mixins' as *;

.slider {
  position: relative;
  width: 30px;
  height: 18px;

  input {
    position: absolute;
    z-index: 2;
    zoom: 2;
    top: -2px;
    left: 1px;
    margin: 0;
    cursor: pointer;
    opacity: 0;

    &:disabled {
      cursor: not-allowed;

      & + span {
        opacity: 0.5;
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $neutral-05;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 33px;

    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      background-color: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + span {
    background-color: $primary-05;

    &::before {
      transform: translateX(12px);
    }
  }
}
