@use 'styles/mixins' as *;

@mixin selector {
  width: 15px;
  height: 15px;
  margin-right: 0.7em;
  transition: all 0.2s ease-in-out;
  border: 1px solid $base-grey-10;
}

.root {
  .option {
    @include text-body;
    display: flex;
    align-items: center;
    color: $base-grey;
    background-color: $surface-secondary;
    border: 1px solid $base-grey-10;
    border-radius: 6px;
    padding: 0.8em 1.4em;
    transition: border-color 0.4s ease-in-out;
    cursor: pointer;
    margin-bottom: 1em;

    .checkbox {
      @include flex-box-centered;
      @include selector;
      background-color: transparent;
      border-radius: 4px;

      .checkIcon {
        color: $white;
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
      }
    }

    .radioButton {
      @include selector;
      position: relative;
      border-radius: 50%;

      &::before {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        top: 2px;
        left: 2px;
        border-radius: 50%;
        background-color: $base-grey-10;
      }
    }

    &:hover {
      border-color: $button-primary;
    }

    &Selected {
      border-color: $button-primary;

      .checkbox {
        background-color: $button-primary;
        border-color: $button-primary;

        .checkIcon {
          opacity: 1;
        }
      }

      .radioButton {
        border-color: $button-primary;

        &::before {
          background-color: $button-primary;
        }
      }
    }
  }

  .otherOption {
    display: block;

    .title {
      display: flex;
    }

    .input {
      margin-top: 1em;

      input {
        background-color: $white;
      }
    }
  }

  .disabled {
    cursor: not-allowed;
    &:hover {
      border-color: $base-grey-10;
    }
  }

  &RadioButton {
    .option {
      border: none;
      background-color: transparent;
      padding: 0;
      margin-bottom: 0.5em;
    }
  }

  .error {
    color: $error-color;
    font-size: 1.1rem;
    text-transform: capitalize;

    &::before {
      content: '* ';
    }
  }
}
