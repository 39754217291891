@use 'styles/mixins' as *;

:root {
  --PhoneInputCountrySelectArrow-color: #333333;
}

.root {
  :global(.PhoneInputInput) {
    @include input;
  }

  :global(.PhoneInputCountry) {
    @include input;
    width: 5em;
  }

  :global(.PhoneInputCountryIcon) {
    width: 30px;
    height: 20px;
    box-shadow: none;
    outline: none;
    background-color: transparent;
  }

  :global(.PhoneInputCountryIconImg) {
    border-radius: 4px;
  }

  :global(.PhoneInputCountrySelect):focus {
    & + :global(.PhoneInputCountryIcon) {
      box-shadow: none;
    }
  }

  &Invalid {
    :global(.PhoneInputInput) {
      border-color: $error-color;

      @include pseudo-states() {
        border-color: $error-color;
      }
    }

    :global(.PhoneInputCountry) {
      border-color: $error-color;

      @include pseudo-states() {
        border-color: $error-color;
      }
    }
  }

  &.invalid {
    margin-bottom: -0.5rem;
  }
}

.error {
  color: $error-color;
  font-size: 1.1rem;
  text-transform: capitalize;

  &::before {
    content: '* ';
  }
}
