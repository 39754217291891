@use 'styles/mixins' as *;

.searchContainer {
  position: relative;
  margin-bottom: 1rem;
}

.suggestionsList {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  background: white;
  z-index: 1000;
  list-style: none;
  padding: 8px;
  margin: 0;
}

.suggestionItem {
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background-color: $neutral-02;
    border-radius: 8px;
  }
}

.manualEntryLink {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
