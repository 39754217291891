@use 'styles/mixins' as *;

.root {
  @include input;
  height: initial;
  padding: 1.2rem;
  resize: none;

  &.invalid {
    margin-bottom: -0.5rem;
  }
}

.error {
  color: $error-color;
  font-size: 1.1rem;
  text-transform: capitalize;

  &::before {
    content: '* ';
  }
}
